<template>
	<div class="modal" :style="{display:display}" @touchmove.prevent.self>
		<div v-if="opened" class="closebg" @click.stop="()=>$emit('closed')"></div>
		<div class="modal-content">
			<div class="modal-header">
				<span class="close" @click="()=>$emit('closed')">&times;</span>
				<div class="label">{{header}}</div>
			</div>
			<div style="top:50px;">
				<slot></slot>
				<!-- <div class="modal-footer">
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'pdialog2',
		data() {
			return {
				display: "none",
				opened:false,
			}
		},
		props: {
			header: {
				type: String,
				default: "窗口"
			},
			width: {
				type: Number,
				default: 1000
			},
			height: {
				type: Number,
				default: 500
			}
		},
		computed: {},
		mounted() {

		},
		methods: {
			stopTouch(e) {
				e.preventDefault()
			},
			open() {
				this.display = "block"
				this.$emit('opend')
				setTimeout(()=>{
					this.opened = true
				},200)
			},
			close() {
				this.display = "none"
				this.opened = false
			}
		},
	}
</script>

<style scoped>
	@import 'fonts.css';

	.label {
		color: #FFFFFF;
		font-size: 20px;
		text-align: center;
		font-family: MideaType-Bold;
		line-height: 48px;
	}

	.noscroll {
		overflow: hidden;
		position: fixed;
	}

	/* 弹窗 */
	.modal {
		display: none;
		/* 默认隐藏 */
		position: fixed;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.closebg {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0, 0, 0, 0.0);
	}

	/* 弹窗内容 */
	.modal-content {
		position: fixed;
		background-color: #fefefe;
		border-radius: 20px 20px 20px 20px;
		top: calc((100vh - 350px)/2);
		left: calc((100vw - 800px)/2);
		width: 800px;
		height: 350px;
		box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px;
	}

	/* 关闭按钮 */
	.close {
		color: #999999;
		float: right;
		font-size: 36px;
		font-weight: bold;
	}

	.close:hover,
	.close:focus {
		color: #FFFFFF;
		text-decoration: none;
		cursor: pointer;
	}

	.modal-header {
		height: 50px;
		left: 50%;
		padding: 2px 16px;
		color: #666666;
		background-color: #575d7f;
		border-radius: 20px 20px 0px 0px;
	}

	.modal-footer {
		height: 10px;
		padding: 2px 4px;
		background: #575d7f;
		color: white;
	}
</style>