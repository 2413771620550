<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 38.25">
		<defs>
			<linearGradient id="a" x1="90" y1="9.63" x2="90" y2="20.66" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color="#54f4ff" />
				<stop offset="1" stop-color="#ccecff" />
			</linearGradient>
			<linearGradient id="b" y1="21.59" x2="180" y2="21.59" gradientUnits="userSpaceOnUse">
				<stop offset="0.31" stop-color="#00b7ff" />
				<stop offset="1" stop-color="#7ad4ff" />
			</linearGradient>
			<linearGradient id="c" x1="282.9" y1="263.27" x2="282.9" y2="284.49" gradientUnits="userSpaceOnUse">
				<stop offset="0.11" stop-color="#5a5a74" />
				<stop offset="1" stop-color="#3c3c5b" />
			</linearGradient>
			<linearGradient id="d" x1="90" y1="12.88" x2="90" y2="27.36" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color="#43607c" stop-opacity="0.58" />
				<stop offset="1" stop-color="#5d8db6" stop-opacity="0.4" />
			</linearGradient>
			<linearGradient id="e" x1="70.57" y1="15.55" x2="104.28" y2="15.55" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color="#999bad" />
				<stop offset="0.23" stop-color="#adafbf" />
				<stop offset="0.42" stop-color="#edf1f8" />
				<stop offset="0.68" stop-color="#5a5a74" />
				<stop offset="1" stop-color="#808196" />
			</linearGradient>
			<linearGradient id="f" x1="90" y1="0.99" x2="90" y2="32.53" xlink:href="#c" />
			<linearGradient id="g" x1="282.93" y1="252.28" x2="282.93" y2="287.51" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color="#fff" />
				<stop offset="1" stop-color="#8d8886" />
			</linearGradient>
		</defs>
		<g class="a">
			<path class="b"
				d="M364.27,277.57H201.58a6.26,6.26,0,0,1-6.24-6.25l-1.28-4.68c2.23-3,3.58-5.15,5.21-7.11a3.67,3.67,0,0,1,3.22-1.22H364.27c1.7,0,2.43.84,3.42,2.21,1.28,1.76,2.92,4.65,4.74,7.21l-1.92,3.59A6.26,6.26,0,0,1,364.27,277.57Z"
				transform="translate(-192.93 -251.32)" />
			<rect class="c" y="12.47" width="180" height="17.3" rx="6.42" />
			<rect class="d" y="12.94" width="180" height="17.3" rx="6.42" />
			<path class="e"
				d="M282.9,285.91a13.27,13.27,0,0,1-13.27-13.05,1.77,1.77,0,0,0,0,.23,13.28,13.28,0,0,0,26.56,0v-.23A13.28,13.28,0,0,1,282.9,285.91Z"
				transform="translate(-192.93 -251.32)" />
			<polygon class="f" points="74.79 14.87 72.15 29.98 107.85 29.98 105.1 14.7 74.79 14.87" />
			<ellipse class="g" cx="90" cy="15.55" rx="15.57" ry="15.55" />
			<ellipse class="h" cx="90" cy="21.6" rx="16.67" ry="16.65" />
			<path class="i"
				d="M282.93,256.73a16.65,16.65,0,0,1,16.66,16.36,2.81,2.81,0,0,0,0-.29,16.68,16.68,0,0,0-33.35,0c0,.1,0,.19,0,.29A16.65,16.65,0,0,1,282.93,256.73Z"
				transform="translate(-192.93 -251.32)" />
			<rect class="j" x="35.99" y="17.54" width="0.19" height="8.37" />
			<rect class="j" x="144.34" y="17.54" width="0.19" height="8.37" />
			<text @click="$emit('change','lsny')" :style='{fill:lsnySceneStyle}' class="k" transform="translate(7.03 23.84)">绿色能源</text>
			<text @click="$emit('change','gy')"   :style='{fill:gySceneStyle}'   class="k" transform="translate(39.01 23.84)">工业自动化</text>
			<text @click="$emit('change','main')" :style='{fill:mainSceneStyle}' class="l" transform="translate(79.01 24.1)">全景图</text>
			<text @click="$emit('change','zhjt')" :style='{fill:zhjtSceneStyle}' class="k" transform="translate(114.56 23.84)">智慧交通</text>
			<text @click="$emit('change','xfdq')" :style='{fill:xfdqSceneStyle}' class="k" transform="translate(148.69 23.84)">消费电器</text>
		</g>
	</svg>
</template>

<script>
	export default {
		name: 'PMenu',
		data() {
			return {
				display: "none"
			}
		},
		props: {
			gy: {
				type: Boolean,
				default: false
			},
			lsny: {
				type: Boolean,
				default: false
			},
			main: {
				type: Boolean,
				default: false
			},
			zhjt: {
				type: Boolean,
				default: false
			},
			xfdq: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			xfdqSceneStyle() {
				return this.xfdq ? "#FFFFFF" : "#0091d7"
			},
			mainSceneStyle() {
				return this.main ? "#FFFFFF" : "#0091d7"
			},
			gySceneStyle() {
				return this.gy ? "#FFFFFF" : "#0091d7"
			},
			zhjtSceneStyle() {
				return this.zhjt ? "#FFFFFF" : "#0091d7"
			},
			lsnySceneStyle() {
				return this.lsny ? "#FFFFFF" : "#0091d7"
			},
		},
		mounted() {
	
		},
		methods: {
			stopTouch(e) {
				e.preventDefault()
			},
			open() {
				this.display = "block"
				//document.body.style.overflow = 'hidden';
				//document.getElementsByTagName('body')[0].className = 'noscroll'
		
			},
			close() {
				this.display = "none"
				//document.body.style.overflow = '';
				//document.getElementsByTagName('body')[0].className = ''
			}
		},
	}
</script>

<style>
	.a {
		isolation: isolate;
	}

	.b {
		fill: #0091d7;
	}

	.c {
		fill: url(#a);
	}

	.d {
		opacity: 0.74;
		fill: url(#b);
	}

	.e {
		fill: url(#c);
	}

	.f {
		mix-blend-mode: multiply;
		opacity: 0.97;
		fill: url(#d);
	}

	.g {
		fill: url(#e);
	}

	.h {
		fill: url(#f);
	}

	.i {
		fill: url(#g);
	}

	.k {
		cursor:pointer;
		font-size: 6.38px;
		font-family: MEIDITYPE-REGULAR-GBpc-EUC-H, Midea Type;
	}

	.l {
		cursor:pointer;
		font-size: 6.94px;
		font-family: MEIDITYPE-BOLD-GBpc-EUC-H, Midea Type;
	}
</style>