<template>
	<div style="overflow:visible;">
		<img class="logo" :style="{right:'0px', width:logoWidth + 'px'}" src="assets/logo2.png">
		<div :style="{transform:scaleStyle, left:updateLeftScene}" style="position: absolute; overflow:visible;">
			<canvas id="pixi" style="position: absolute;  overflow:visible;" />
			<div v-if="bgHeight >= bgWidth" @pointerdown="clickHandler" @pointerup="clickHandler"
				@pointerover="clickHandler" @pointerout="clickHandler" @touchstart="clickHandler"
				@touchend="clickHandler"
				style="position: absolute; font-size: 20px; width:1920px; height:1080px; background-color:rgba(0, 0, 0, 0.0);">
			</div>
		</div>
		<div v-show="!modalIsOpen && loadded" class="menu">
			<Menu class="nav" :main="currentScene === mainScene" :xfdq="currentScene === xfdqScene"
				:gy="currentScene === gyScene" :zhjt="currentScene === zhjtScene" :lsny="currentScene === lsnyScene"
				@change="gotoScene($event)"></Menu>
		</div>

		<div v-if="!loadded" class="pacity loading">
			<p class="loadnum">{{loadPercent}}</p>
			<img style="width:356px;" src="assets/loading.png" />
		</div>

		<Pdialog v-if="bgHeight >= bgWidth" v-show="modalIsOpen" ref="dialog" :header="currCPItem.label"
			@opend="dialogOpendHandler" @closed="dialogClosedHandler">
			<div :style='{height:innerHeight + "px"}' class="ff label">
				<img style="text-align:center;object-fit:scale-down;" height="260px" :src="currCPItem.cpImg"
					@click="gotoMidea" />
				<!-- <div class="dots">
					<li v-for="i in totalPage" :key="i" :class="{dotted: i === infoPage+1}"
						:style="{width:lineWidth + 'px'}">
					</li>
				</div> -->

				<div ref="info" id="info" @touchmove.stop.passive
					:style="{height:textHeight + 'px',margin:'10px', padding:'0px 30px 0px', overflow:'scroll'}">
					<div v-if="currCPItem.html" class="text" style="white-space: pre-line;line-height: 1.4em;" v-html="currCPItem.info">
						<!-- {{currCPItem.info}} -->
					</div>
					<div v-else class="text">
						{{currCPItem.info}}
					</div>
				</div>
			</div>
		</Pdialog>

		<Pdialog2 v-if="bgHeight < bgWidth" v-show="modalIsOpen" ref="dialog" :header="currCPItem.label"
			:style="{opacity:dialogOpacity , scale:dialogScale}" style="transform-origin:bottom"
			@opend="dialogOpendHandler" @closed="dialogClosedHandler">
			<div class="ff2">
				<div class="imgcont">
					<img style="height:100%; object-fit:contain;" :src="currCPItem.cpImg" @click="gotoMidea" />
				</div>
				<div v-if="currCPItem.html" ref="info" id="info" class="context" @touchmove.stop.passive>
					<div class="text" style="white-space: pre-line;line-height: 1.4em;" v-html="currCPItem.info">
						<!-- {{currCPItem.info}} -->
					</div>
				</div>
				<div v-else ref="info" id="info" class="context" @touchmove.stop.passive>
					<div class="text">
						{{currCPItem.info}}
					</div>
				</div>
			</div>
		</Pdialog2>
	</div>
</template>
<script>
	import Pdialog from './info.vue'
	import Pdialog2 from './info2.vue'
	import Menu from './menu.vue'
	import {
		createGYScene,
		createXFDQScene,
		createMainScene,
		createZHJTScene,
		createLSNYScene
	} from '@/comm/scene.js'
	import {
		gsap
	} from "gsap";
	import {
		PixiPlugin
	} from "gsap/PixiPlugin";
	import {
		MotionPathPlugin
	} from "gsap/MotionPathPlugin";

	export default {
		name: "pixi2",
		props: {},
		data() {
			return {
				application: null,
				bg: 0,
				loadPercent: 0,
				bgHeight: null,
				bgWidth: null,
				stageWidth: 1920,
				stageHeight: 1080,
				mousedown: false,
				canvas: null,
				btns: [],
				click: 0,
				lineWidth: 30,
				infoPage: 0,
				totalPage: 0,
				currCPItem: {},
				sliderValue: 2,
				xfdqScene: null,
				currentScene: null,
				gyScene: null,
				mainScene: null,
				zhjtScene: null,
				lsnyScene: null,
				xfdqSceneInited: false,
				gySceneInited: false,
				zhjtSceneInited: false,
				lsnySceneInited: false,
				filter: null,
				modalIsOpen: false,
				currentWindowScrollX: 0,
				currentSceneName: "main",
				loadded: false,
				leftScene: 0,
				dialogOpacity: 0.0,
				dialogScale: 0.8,
				logoRight: 0,
			};
		},
		components: {
			//Img,
			Pdialog,
			Pdialog2,
			Menu
			//Slider,
			//SliderItem
		},
		// updated(){
		// 	this.handleScroll()
		// },
		mounted() {
			// if (window.history && window.history.pushState) {
			// 	window.onpopstate = function() {
			// 		window.history.pushState('forward', null, '');
			// 		window.history.forward(1);
			// 	};
			// }
			// window.history.pushState('forward', null, ''); //在IE中必须得有这两行
			// window.history.forward(1);

			if (window.history && window.history.pushState) {
				history.pushState(null, null, document.URL);
				window.addEventListener('popstate', this.goBack);
			}
			this.initState();
			window.addEventListener("resize", () => {
				this.rendererResize()
			});
			this.rendererResize()
			let dom = this.$refs["info"]
			dom.addEventListener('scroll', this.handleScroll, true)
		},

		beforeDestroy() {
			let dom = this.$refs["info"]
			dom.removeEventListener('scroll', this.handleScroll, true)
			window.removeEventListener('popstate', this.goBack);
		},
		computed: {
			logoWidth() {
				if (this.bgWidth < this.bgHeight) {
					return parseInt(this.bgWidth * 0.2)
				}
				return parseInt(this.bgHeight * 0.2)
			},
			updateLeftScene() {
				if (this.bgWidth <= this.stageWidth * this.scale) {
					return '0px'
				}
				return parseInt((this.bgWidth - this.stageWidth * this.scale) / 2) + 'px'
			},
			xfdqSceneStyle() {
				return this.currentScene === this.xfdqScene ? "#FFFFFF" : "#999999"
			},
			mainSceneStyle() {
				return this.currentScene === this.mainScene ? "#FFFFFF" : "#999999"
			},
			gySceneStyle() {
				return this.currentScene === this.gyScene ? "#FFFFFF" : "#999999"
			},
			zhjtSceneStyle() {
				return this.currentScene === this.zhjtScene ? "#FFFFFF" : "#999999"
			},
			lsnySceneStyle() {
				return this.currentScene === this.lsnyScene ? "#FFFFFF" : "#999999"
			},
			innerHeight() {
				return this.bgHeight * 0.7
			},
			textHeight() {
				return this.innerHeight - 260
			},
			ratio() {
				return window.devicePixelRatio
			},
			scaleStyle() {
				let s = this.bgHeight / this.stageHeight
				return 'scale(' + s + ')'
			},
			scale() {
				return this.bgHeight / this.stageHeight
			},
			scaleFixed() {
				return (this.bgHeight / this.stageHeight).toFixed(2)
			},
			pages() {
				let dom = this.$refs["info"]
				if (dom) {
					return parseInt(dom.scrollHeight / this.textHeight)
				}
				return 0
			}
		},
		methods: {
			gotoMidea() {
				window.open(this.currCPItem.url, '_blank')
			},
			goBack() {
				if (this.modalIsOpen) {
					this.dialogClosedHandler()
					history.pushState(null, null, document.URL); // 禁止跳转
					setTimeout(() => {
						window.scrollTo({
							left: this.currentWindowScrollX
						})
					}, 1)

				} else if (this.currentScene !== this.mainScene) {
					this.gotoScene("main")
					history.pushState(null, null, document.URL); // 禁止跳转
				}
			},
			updateScroll() {
				let dom = this.$refs["info"]
				this.totalPage = parseInt(dom.scrollHeight / this.textHeight)
				this.lineWidth = (dom.scrollWidth - (20 * this.totalPage)) / this.totalPage
			},
			handleScroll() {
				let dom = this.$refs["info"]
				this.infoPage = parseInt(dom.scrollTop / this.textHeight)
			},
			changeIndex(index) {
				this.sliderValue = index;
			},
			clone(e) {
				return new e.constructor(e.type, e);
			},
			clickHandler(e) {
				let ee = this.clone(e)
				this.canvas.dispatchEvent(ee);
			},
			openDialog() {
				this.dialogScale = 0.8
				this.modalIsOpen = true;
				this.$refs.dialog.open()
				gsap.to(this, {
					dialogScale: 1,
					dialogOpacity: 1.0,
					duration: 0.2
				});
			},
			closeDialog() {
				if (this.bgHeight >= this.bgWidth) {
					this.$refs.dialog.close()
					setTimeout(() => {
						this.modalIsOpen = false;
					}, 200)
				} else {
					gsap.to(this, {
						dialogScale: 0.8,
						dialogOpacity: 0.0,
						duration: 0.1,
						ease: "sine.in",
						onComplete: () => {
							this.modalIsOpen = false;
							this.$refs.dialog.close()
						}
					});
				}
			},
			dialogOpendHandler() {
				this.currentWindowScrollX = window.scrollX
			},
			dialogClosed() {
				let dom = this.$refs["info"]
				dom.scrollTo(0, 0)
				this.totalPage = parseInt(dom.scrollHeight / this.textHeight)
				this.modalIsOpen = false;
				this.$refs.dialog.close()
			},
			dialogClosedHandler() {
				let dom = this.$refs["info"]
				dom.scrollTo(0, 0)
				this.totalPage = parseInt(dom.scrollHeight / this.textHeight)
				this.closeDialog()
			},
			gotoScene(sceneName) {
				if (this.currentSceneName === sceneName) {
					return
				}
				this.currentSceneName = sceneName
				let targetScene = null
				switch (sceneName) {
					case "gy":
						if (!this.gySceneInited) {
							this.gySceneInited = true
							createGYScene(this.PIXI, this.gyScene, (item) => {
								this.click++
								this.currCPItem = item
								this.openDialog()
								setTimeout(() => {
									this.updateScroll()
								}, 200)
							})
						}
						targetScene = this.gyScene
						break;
					case "xfdq":
						if (!this.xfdqSceneInited) {
							this.xfdqSceneInited = true
							createXFDQScene(this.PIXI, this.xfdqScene, (item) => {
								this.click++
								this.currCPItem = item
								this.openDialog()
								setTimeout(() => {
									this.updateScroll()
								}, 200)
							})
						}
						targetScene = this.xfdqScene
						break;
					case "zhjt":
						if (!this.zhjtSceneInited) {
							this.zhjtSceneInited = true
							createZHJTScene(this.PIXI, this.zhjtScene, (item) => {
								this.click++
								this.currCPItem = item
								this.openDialog()
								setTimeout(() => {
									this.updateScroll()
								}, 200)
							})
						}
						targetScene = this.zhjtScene
						break;
					case "lsny":
						if (!this.lsnySceneInited) {
							this.lsnySceneInited = true
							createLSNYScene(this.PIXI, this.lsnyScene, (item) => {
								this.click++
								this.currCPItem = item
								this.openDialog()
								setTimeout(() => {
									this.updateScroll()
								}, 200)
							})
						}
						targetScene = this.lsnyScene
						break;
					case "main":
						targetScene = this.mainScene
						break;
				}

				gsap.to(this.currentScene, {
					alpha: 0.0,
					duration: 0.5,
					onComplete: () => {
						this.currentScene.visible = false
						targetScene.visible = true
						targetScene.alpha = 0
						this.currentScene = targetScene

						let options = {
							left: 0
						}

						gsap.to(options, {
							left: (this.stageWidth * this.scale - window.innerWidth) / 2,
							duration: 1,
							onUpdate: () => {
								window.scrollTo(options)
							}
						});

						gsap.to(this.currentScene, {
							alpha: 1,
							duration: 0.5
						});
					}
				})
			},
			async initState() {
				gsap.registerPlugin(PixiPlugin, MotionPathPlugin)

				let pixi = document.getElementById('pixi')
				let app = new this.PIXI.Application({
					width: this.stageWidth,
					height: this.stageHeight,
					resolution: 1,
					//resizeTo: window,
					backgroundAlpha: 0,
					view: pixi
				})
				this.canvas = pixi
				this.application = app

				this.filter = new this.PIXI.filters.ColorMatrixFilter();

				this.mainScene = new this.PIXI.Container();
				this.currentScene = this.mainScene
				this.mainScene.filters = [this.filter];
				this.application.stage.addChild(this.mainScene)

				this.zhjtScene = new this.PIXI.Container();
				this.zhjtScene.filters = [this.filter];
				this.zhjtScene.visible = false
				this.application.stage.addChild(this.zhjtScene)

				this.xfdqScene = new this.PIXI.Container();
				this.xfdqScene.filters = [this.filter];
				this.xfdqScene.visible = false
				this.application.stage.addChild(this.xfdqScene)

				this.lsnyScene = new this.PIXI.Container();
				this.lsnyScene.filters = [this.filter];
				this.lsnyScene.visible = false
				this.application.stage.addChild(this.lsnyScene)

				this.gyScene = new this.PIXI.Container();
				this.gyScene.filters = [this.filter];
				this.gyScene.visible = false
				this.application.stage.addChild(this.gyScene)

				this.PIXI.Assets.load([
					"assets/light.json",
					"assets/main/main-bg.png",
					"assets/main/main-0.json",
					"assets/xfdq/xfdq-bg.png",
					"assets/xfdq/xfdq.json",
					"assets/gy/gy-bg.png",
					"assets/gy/gy-0.json",
					"assets/lsny/lsny-bg.png",
					"assets/lsny/lsny.json",
					"assets/zhjt/zhjt-bg.png",
					"assets/zhjt/zhjt.json"
				], (process) => {
					if (process > 0.99) {
						process = 0.99
					}
					this.loadPercent = parseInt(process * 100)
				}).then(() => {
					gsap.to(".pacity", {
						opacity: 0.0,
						duration: 0.3,
						onComplete: () => {
							this.initMainScene()
						}
					});
				});
			},
			initMainScene() {
				this.loadded = true
				createMainScene(this.PIXI, this.mainScene, (item) => {
					this.gotoScene(item.name)
				})

				let options = {
					left: 0,
				}

				gsap.to(options, {
					left: (this.stageWidth * this.scale - window.innerWidth) / 2,
					duration: 1,
					onUpdate: () => {
						window.scrollTo(options)
					}
				});

				gsap.to(this.filter, {
					brightness: 1,
					duration: 1
				});
			},
			rendererResize() {
				[this.bgWidth, this.bgHeight] = [window.innerWidth, window.innerHeight];
			}
		}
	}
</script>
<style scoped>
	@import 'fonts.css';
	.ff {
		display: flex;
		flex-direction: column;
	}

	.imgcont {
		/* position: absolute; */
		/* background-color: #555555; */
		width: 40%;
		height: 80%;
		text-align: center;
		padding: 10px;
	}

	.logo {
		min-width: 100px;
		/* width: 100px; */
		position: fixed;
		top: 0;
		z-index: 4;
	}

	.ff2 {
		display: flex;
		height: 300px;
		/* margin-top: 20px; */
		align-items: center;
		/* justify-content: center; */
	}

	.context {
		align-self: center;
		/* height: 100%; */
		max-height: 80%;
		width: 60%;
		overflow: auto;
		margin-left: 20px;
		margin-right: 30px;
		padding-right: 30px;
		margin-bottom: 10px;
		/* background-color: #555555; */
	}

	.label {
		align-items: center;
		justify-content: center;
	}

	.text {
		color: #666666;
		font-size: 15px;
		font-family: MideaType-Bold;
		text-align: justify;
		vertical-align: middle;
	}
	
	li{
		list-style-type:none;
	}
	
	.text li{
		list-style-type:none;
	}
	
	.dots {
		height: 30px;
	}

	.dots li {
		display: inline-block;
		padding: 0;
		margin: 0;
		height: 3px;
		margin: 0 3px;
		border-radius: 3px;
		background-color: #999;
		cursor: pointer;
	}

	.dots .dotted {
		background-color: #0091d7;
	}

	.menu {
		width: 100%;
		position: fixed;
		z-index: 2;
		bottom: 10px;
		color: #666666;
		text-align: center;
		user-select: none;
	}

	.loading {
		width: 100%;
		position: fixed;
		bottom: 50%;
		z-index: 3;
		color: #5781b4;
		text-align: center;
	}

	.loadnum {
		font-weight: 500;
		font-family: MideaType-Bold;
		position: relative;
		left: -66px;
		top: 134px;
		font-size: 20px;
		transform: rotate3d(1, 1.5, 0.6, 45deg)
	}

	.nav {
		width: 100%;
		min-width: 180px;
		max-width: 480px;
		list-style-type: none;
		font-weight: 800;
		font-family: MideaType-Bold;
	}

	.fadenum {
		animation: fadeio 2s infinite;
		-webkit-animation: fadeio 2s infinite;
		-moz-animation: fadeio 2s infinite;
		-o-animation: fadeio 2s infinite;
	}

	.nav li {
		display: inline-block;
		font-size: 18px;
		padding: 8px;
		padding-top: 38px;
	}
</style>