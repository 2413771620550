export const gy = [{
		type: "anima",
		src: "GY-lvdai-A",
		x: 498,
		y: 100
	},
	{
		type: "anima",
		src: "GY-lvdai-B",
		x: 1186,
		y: 253
	},
	{
		type: "anima",
		src: "GY-dingbu",
		x: 935,
		y: 128
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "GY-washmachine.png",
		x: 535,
		y: 402,
		num: 10,
		delay: 2,
		duration: 20, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 535,
				y: 402
			}, {
				x: 932,
				y: 165
			}, {
				x: 1028,
				y: 128
			}, {
				x: 1065,
				y: 151
			}, {
				x: 1132,
				y: 194
			}, {
				x: 1579,
				y: 470
			}]
		}
	},
	{
		type: "anima",
		src: "GY-arm",
		x: 1303,
		y: 235,
	},
	{
		type: "anima",
		delay: 0.5,
		src: "GY-arm",
		x: 1449,
		y: 315,
	},
	{
		type: "anima",
		src: "GY-arm",
		turn: true,
		x: 713,
		y: 253,
	},
	{
		type: "anima",
		src: "GY-armB",
		x: 1024,
		y: 727
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "anima", //png单帧，anima多帧动画
		src: "GY-carryrobot",
		x: 510,
		y: 614,
		num: 1,
		delay: 4,
		duration: 20, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 510,
				y: 614
			}, {
				x: 610,
				y: 678
			}, {
				x: 512,
				y: 744
			}, {
				x: 422,
				y: 665
			}, {
				x: 510,
				y: 614
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "anima", //png单帧，anima多帧动画
		src: "GY-carryrobot",
		x: 1012,
		y: 686,
		num: 5,
		delay: 4,
		duration: 20, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1012,
				y: 686
			}, {
				x: 673,
				y: 504
			}, {
				x: 972,
				y: 313
			}, {
				x: 1284,
				y: 532
			}, {
				x: 1012,
				y: 686
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "anima",
		src: "GY-databoard",
		x: 672,
		y: 578
	},
	{
		type: "mask",
		src: "GY-HMI.png",
		x: 324,
		y: 371
	},
	{
		type: "anima",
		src: "GY-HMI",
		x: 545,
		y: 443
	},
	{
		type: "anima",
		src: "GY-jiqishijue",
		x: 718,
		y: 195
	},
	{
		type: "anima",
		src: "GY-kezi",
		x: 1301,
		y: 415
	},
	{
		type: "mask",
		src: "GY-lvdai.png",
		x: 769,
		y: 148
	},
	{
		type: "anima",
		src: "GY-chanche",
		x: 1646,
		y: 736
	},
	{
		type: "light-btn",
		size: "M",
		src: "GY-01.png",
		label: "PLC/控制器",
		cpImg: "assets/img/gy-cp/gy-01.png",
		html: true,
		info: `<li>实时性高、精简且运行稳定</li>
<li>点位运动控制功能，速度同步、位置同步功能</li>
<li>G代码插补功能（直线插补/圆弧插补）</li>
<li>机器人坐标转换模型</li>
<li>集成典型行业工艺算法包，如张力控制、柔性龙门等</li>
<li>支持多种通讯协议</li>
<li>网页访问可视化界面，实现远距离操控</li>`,
		x: 345,
		y: 359,
		lx: 0,
		ly: 0,
	},
	{
		type: "light-btn",
		size: "M",
		src: "GY-02.png",
		label: "低压变频器",
		html:true,
		cpImg: "assets/img/gy-cp/gy-02.png",
		info: `<li>精巧，手机屏式结构，节省空间，较上一代产品体积减小55%</li>
			 <li>简便，内置行业专用宏，一键设置行业参数</li>
			 <li>高性能，全新的软件控制算法，高启动转矩功能，同步电机、异步电机驱动一体化</li>`,
		x: 315,
		y: 544,
		lx: 0,
		ly: 17,
	},
	{
		type: "light-btn",
		size: "S",
		src: "GY-03.png",
		label: "HMI",
		cpImg: "assets/img/gy-cp/gy-03.png",
		html: true,
		info: `<li>7寸/10寸 满足大部分应用场合</li>
<li>支持CODESYS V3标签通讯</li>
<li>支持ModbusTCP、RS232、RS485通讯</li>
<li>IP65高防护前面板设计</li>`,
		x: 588,
		y: 437,
		lx: -20,
		ly: -16,
	},//●&ensp;&ensp;
	{
		type: "light-btn",
		size: "M",
		src: "GY-04.png",
		label: "伺服驱动器",
		cpImg: "assets/img/gy-cp/gy-04.png",
		html:true,
		info: `<li>一键自整定功能，节省90%调试时间</li>
		<li>HDM控制算法：基于机械模型的频域设计方式，通过扫频，建模，设计和验证四个步骤，设计最优伺服控制增益及滤波参数，从而实现伺服最大带宽运行</li>
		<li>刚性负载振动抑制：输入整形（低频振动抑制）+A型振动抑制（中频振动抑制）+自适应陷波器（高频振动抑制）</li>
		<li>弹性负载振动抑制：输入整形+相位补偿，末端振动抑制</li>
		<li>碰撞检测功能，开环力控功能，简化系统结构，免除压力检测传感器成本</li>
		<li>可以搭配PM2&PH2系列电机组成伺服系统，应对不同的设备性能要求</li>
		<li>产品符合 CE 认证要求，满足海外市场相关设备安全要求</li>
		`,
		x: 502,
		y: 194,
		lx: 0,
		ly: 0,
	},
	{
		type: "light-btn",
		size: "S",
		src: "GY-05.png",
		label: "机器视觉",
		cpImg: "assets/img/gy-cp/gy-05.png",
		html: true,
		info: `<li>精度高：通过先进的光学系统设计及精密标定，结合热稳定技术达到业界领先的高精度测量</li>
<li>抗干扰：采用首创二维编解码有效解决强反，多反以及强光等挑战场景下成像质量</li>
<li>极致设计：结合工业美学及实用为一体的创新设计，具备工业IP65防尘等级，高效散热设计，更适应恶劣作业环境</li>
<li>智能化程度高：一体式AI计算平台，轻松处理复杂工况成像质量需求</li>
<li>开放性强：搭配配套SDK软件，可轻松快速部署各种智能工业机器人应用</li>
<li>极具性价比：一体化解决方案，降低用户TOC，快速取得收益</li>
`,
		x: 733,
		y: 93,
		lx: -22,
		ly: -17,
	},
	{
		type: "light-btn",
		size: "S",
		src: "GY-06.png",
		label: "伺服电机",
		html:true,
		cpImg: "assets/img/gy-cp/gy-06.png",
		info: `<li>高精度</li> 
			 <li>高防护</li>
			 <li>高抗振</li>
			 <li>高可靠性</li>
			 <li>高过载能力</li>`,
		x: 1379,
		y: 190,
		lx: -20,
		ly: -15,
	},
	{
		type: "light-btn",
		size: "S",
		src: "GY-07.png",
		label: "编码器",
		html:true,
		cpImg: "assets/img/gy-cp/gy-07.png",
		info: `<li>高分辨率</li> 
			 <li>高鲁棒性</li>
			 <li>可靠性高</li> 
			 <li>环境适应性强</li>`,
		x: 1532,
		y: 280,
		lx: -36,
		ly: -23,
	},
	{
		type: "light-btn",
		size: "M",
		src: "GY-08.png",
		label: "直线驱动器",
		cpImg: "assets/img/gy-cp/gy-08.png",
		html: true,
		info: `<li>支持直线电机 DD马达 音圈电机 伺服电机等多种电机类型</li>
<li>提供多种反馈装置接口</li>
<li>双闭环控制的第二编码器接口</li>
<li>内置驱动器配置和诊断的操作面板</li>
<li>位置比较输出模块</li>
<li>内置支持刚性和柔性龙门系统</li>
<li>1D 误差纠正补偿表</li>
<li>高级控制算法实现设备最高精度和最大产能</li>
<li>高功率密度</li>
<li>安全转矩关闭功能 (STO)</li>
<li>采用 ServoStudioTM 2.0 GUI 调试，全面的综合参数进行优化配置</li>
<li>输入电压 :20V 至 480V</li>`,
		x: 1200,
		y: 504,
		lx: -11,
		ly: -6,
	},
	{
		type: "light-btn",
		size: "M",
		src: "GY-09.png",
		label: "工业互联网",
		html:true,
		cpImg: "assets/img/gy-cp/gy-09.png",
		info: `<li>敬请期待</li>`,
		x: 846,
		y: 572,
		lx: 3,
		ly: -1,
	},
	{
		type: "light-btn",
		size: "S",
		src: "GY-10.png",
		label: "减速机",
		html:true,
		cpImg: "assets/img/gy-cp/gy-10.png",
		info: `<li>高可靠，10000小时耐久精度小于1arcmin</li>
			<li>高密封，长时间运行无漏油</li>
			<li>低噪音，产品运行噪音低</li>
			<li>高耐久，经过10000小时长时间耐久试验</li>`,
		x: 1020,
		y: 822,
		lx: -10,
		ly: -10,
	}
]