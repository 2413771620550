export const zhjt = [{
		type: "mask",
		src: "ZHJT-LINE.png",
		x: 508,
		y: 306
	},
	{
		type: "anima",
		src: "ZHJT-bike",
		x: 562,
		y: 612
	},
	{
		type: "mask",
		src: "ZHJT-bike.png",
		x: 562,
		y: 611
	},
	{
		type: "mask",
		src: "ZHJT-bigcar.png",
		x: 793,
		y: 406
	},
	{
		type: "anima",
		src: "ZHJT-carlight",
		x: 769,
		y: 385
	},
	{
		type: "anima",
		delay: 0,
		src: "ZHJT-wave",
		x: 1472,
		y: 177
	},
	{
		type: "mask",
		src: "ZHJT-ship.png",
		x: 1427,
		y: 157
	},
	{
		type: "mask",
		src: "ZHJT-ship.png",
		x: 1427,
		y: 157
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "ZHJT-airplane.png",
		x: -246,
		y: 470,
		num: 1,
		delay: 0,
		duration: 5, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: -246,
				y: 470
			}, {
				x: 930,
				y: -261
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "ZHJT-train.png",
		x: -302,
		y: 330,
		num: 1,
		delay: 0,
		duration: 15, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: -302,
				y: 330
			}, {
				x: 1402,
				y: 1310
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "mask",
		src: "ZHJT-bridge.png",
		x: 635,
		y: 868
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "ZHJT-car03.png",
		x: 2295,
		y: 110,
		num: 2,
		delay: 2,
		duration: 10, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 2295,
				y: 110
			}, {
				x: 590,
				y: 1100
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},

	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "ZHJT-car01.png",
		x: 2303,
		y: 100,
		num: 2,
		delay: 5,
		duration: 20, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 2303,
				y: 100
			}, {
				x: 590,
				y: 1098
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "ZHJT-car02.png",
		x: 2303,
		y: 95,
		num: 4,
		delay: 5,
		duration: 20, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 2303,
				y: 95
			}, {
				x: 590,
				y: 1098
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "ZHJT-car04.png",
		x: 2550,
		y: 50,
		num: 1,
		delay: 0,
		duration: 10, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 2550,
				y: 50
			}, {
				x: 590,
				y: 1190
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "ZHJT-car06.png",
		x: 2590,
		y: 75,
		num: 3,
		delay: 3,
		duration: 8, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 2590,
				y: 75
			}, {
				x: 600,
				y: 1200
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "light-btn",
		size: "M",
		src: "ZHJT-01.png",
		label: "助力电机",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-01.png",
		info: `<li>智能，自主智能调教算法，完全同步人体脚踏过程</li>
			 <li>静音，独有静音设计和制造工艺，比肩业内标杆产品</li>
			 <li>高效，全范围段行业领先</li>
			 <li>强劲，超高过载能力，75%满负载连续运行外壳不过温</li>`,
		x: 367,
		y: 387,
		lx: -23,
		ly: -13,
	},
	{
		type: "light-btn",
		size: "L",
		src: "ZHJT-02.png",
		label: "电子助力转向电机",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-02.png",
		info: `<li>高灵敏度，低噪音，冗余设计，紧凑型设计</li>
			 <li>可根据客户的需求进行定制开发</li>
			 <li>高灵敏度，大扭矩出力，满足辅助驾驶需求</li>
			 <li>低噪音，提供舒适的驾乘体验</li>
			 <li>冗余设计，提供双重安全保障</li>
			 <li>紧凑型设计，减少系统质量</li>`,
		x: 433,
		y: 286,
		lx: 12,
		ly: 2,
	},
	{
		type: "light-btn",
		size: "M",
		src: "ZHJT-03.png",
		label: "电子水泵",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-03.png",
		info: `<li>高效率，低噪音，高可靠性，轻量化及紧凑化</li>
			 <li>提供更加舒适的驾乘体验，助力整车电耗优化，使用寿命更长，利于整车布局或集成化设计</li>`,
		x: 616,
		y: 241,
		lx: -19,
		ly: -13,
	},
	{
		type: "light-btn",
		size: "M",
		src: "ZHJT-04.png",
		label: "电子油泵",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-04.png",
		info: `<li>极佳的低温启动特性，低噪音，高可靠性，轻量化及紧凑化，兼容多通讯模式</li>
			 <li>能够在极限工况下确保电机有效润滑，提供更加舒适的驾乘体验，使用寿命更长，利于整车布局或集成化设计</li>`,
		x: 734,
		y: 176,
		lx: -22,
		ly: -12,
	},
	{
		type: "light-btn",
		size: "M",
		src: "ZHJT-05.png",
		label: "电动压缩机",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-05.png",
		info: `<li>宽转速范围、高能效、高稳定、低噪音</li>
			 <li>超高转速12000rpm，提高车辆续航里程，超静音，提供给用户安静舒适的驾乘体验</li>
			 <li>高效稳定，提高电池热管理效率</li>
			 <li>轻量化、小尺寸，减少整车重量</li>`,
		x: 835,
		y: 97,
		lx: -4,
		ly: -5,
	},
	{
		type: "light-btn",
		size: "M",
		src: "ZHJT-06.png",
		label: "驱动电机",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-06.png",
		info: `<li>高功率密度、高转速、高效率 </li>
			 <li>可根据客户的需求定制不同功率/扭矩的驱动总成，创新的高转速高扭矩总成拓扑结构设计，提供轻量化低成本解决方案，高功率密度、高效率、低NVH，匹配驱动总成/整车要求</li>`,
		x: 990,
		y: 185,
		lx: -17,
		ly: -10,
	},
	{
		type: "light-btn",
		size: "L",
		src: "ZHJT-07.png",
		label: "空气悬架供气单元",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-07.png",
		info: `<li>升降速率更高，小型集成设计，低噪音，可灵活配置ECU</li>
			 <li>整车充气性能改善，升降速率更高</li>
			 <li>小型集成式设计，节约整车安装空间，减少线束成本</li>
			 <li>节约气路成本，提升充气效率</li>
			 <li>可根据客户情况灵活配置ECU</li>
			 <li>低噪音，提供舒适的驾乘体验</li>`,
		x: 1108,
		y: 257,
		lx: 10,
		ly: 2,
	},
	{
		type: "light-btn",
		size: "L",
		src: "ZHJT-08.png",
		label: "手感模拟转向电机",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-08.png",
		info: `<li>迅速响应，操控性提升，降低风险，双重保障</li>
			 <li>直驱式架构，响应灵敏迅速；延迟极大降低，操控性显著提升</li>
			 <li>扭矩大幅提升，覆盖所有驾驶工况</li>
			 <li>省去传统机械限位，无变速箱机构，失效风险降低</li>
			 <li>冗余设计，提供双重安全保障</li>`,
		x: 1137,
		y: 469,
		lx: 10,
		ly: 3,
	},
	{
		type: "light-btn",
		size: "M",
		src: "ZHJT-09.png",
		label: "电子膨胀阀",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-09.png",
		info: `<li>超长寿命，故障率低，模块化设计</li>
			 <li>30万次超长使用寿命，故障诊断防失步，提高控制精度，模块化设计便于集成</li>`,
		x: 1023,
		y: 534,
		lx: -5,
		ly: -5,
	},
	{
		type: "light-btn",
		size: "L",
		src: "ZHJT-10.png",
		label: "热管理集成模组",
		html:true,
		cpImg: "assets/img/zhjt-cp/zhjt-10.png",
		info: `<li>高度集成化，空间紧凑，易于布置</li>
			 <li>大幅降低系统复杂性，减少整车的部件数量及制造工序，匹配下一代整车电气架构发展趋势</li>
			 <li>整车能量管理效率提升，质量可靠，优化前舱空间，提升美学体验</li>`,
		x: 911,
		y: 599,
		lx: -4,
		ly: -6,
	}
]