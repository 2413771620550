export const xfdq = [
	{
		type:"anima",
		src:"XFDQ-01computer",
		x: 777,
		y: 771
	},
	{
		type:"anima",
		src:"XFDQ-02washmachine",
		x: 1077,
		y: 695
	},
	{
		type:"anima",
		src:"XFDQ-03aircondition",
		x: 680,
		y: 430
	},
	{
		type:"anima",
		src:"XFDQ-04BBQ",
		x: 791,
		y: 256
	},
	{
		type:"anima",
		src:"XFDQ-customer-a",
		x: 1342,
		y: 477
	},
	{
		type:"anima",
		src:"XFDQ-customer-b",
		x: 1338,
		y: 522
	},
	{
		type:"anima",
		src:"XFDQ-customer-c",
		x: 1299,
		y: 535
	},
	{
		type:"light-btn",
		size:"M",
		src:"XFDQ-01.png",
		label:"冰箱压缩机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-01.png",
		info:`<li>高效、小型、经济、高可靠性技术解决方案</li>
			 <li>多系列、多型谱、广泛应用，采用环保型制冷剂 R600a、R134a</li>`,
		x: 1129,
		y: 778,
		lx:0,
		ly:0,
	},
	{
		type:"light-btn",
		size:"L",
		src:"XFDQ-02.png",
		label:"车载/移动制冷压缩机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-02.png",
		info:`<li>高效节能，房车专用卧式压缩机，制冷能力出色，高能效</li>
			 <li>小巧身材，卧式结构，适用于车载等有高度限制的领域</li>
			 <li>可靠稳定，卧式压缩机专利供油技术 ，零部件润滑充分，保证可靠稳定运行</li>`,
		x: 1061,
		y: 35,
		lx:34,
		ly:17,
	},
	{
		type:"light-btn",
		size:"M",
		src:"XFDQ-03.png",
		label:"电子膨胀阀",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-03.png",
		info:`<li>轴承减磨，可靠性更高</li>
			 <li>可集成滤网，简化系统管路</li>
			 <li>动作快速，节能高效</li>
			 <li>线圈可360度安装，接地设计，避免电磁干扰</li>`,
		x: 429,
		y: 286,
		lx:0,
		ly:0,
	},
	{
		type:"light-btn",
		size:"M",
		src:"XFDQ-04.png",
		label:"空调电机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-04.png",
		info:`<li>内机低噪音，正弦化磁场设计，最大限度抑制电磁噪音</li>
			 <li>外机高防水，过盈密封防水技术，防护等级IPX5</li>
			 <li>高能效，IPM拓扑结构、多极配合磁场调制技术、小气隙、防漏磁设计多维度提高能效</li>`,
		x: 629,
		y: 194,
		lx:-20,
		ly:-10,
	},
	{
		type:"light-btn",
		size:"M",
		src:"XFDQ-05.png",
		label:"空调压缩机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-05.png",
		info:`<li>环保新冷媒，覆盖R32、R290和R454B等多种新冷媒</li>
			 <li>小型化高能效，压缩机能效提升8%，系统APF能效提升6%，满足各能效级产品</li>
			 <li>静音，全系双缸产品，多重降噪结构设计，极致低噪音低振动，系统听感更优</li>`,
		x: 314,
		y: 328,
		lx:0,
		ly:0,
	},
	{
		type:"light-btn",
		size:"M",
		src:"XFDQ-06.png",
		label:"轻商压缩机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-06.png",
		info:`<li>高效、安全、可靠的轻商冰压技术解决方案</li>
			 <li>广泛应用于商用冰箱冷柜、轻商设备等</li>
			 <li>智能化系统方案，打造高端售后服务</li>`,
		//x: 1525,
		//y: 305,
		x: 1546,
		y: 492,
		lx:0,
		ly:0,
	},
	{
		type:"light-btn",
		size:"S",
		src:"XFDQ-07.png",
		label:"商用电机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-07.png",
		info:`<li>高能效，全新拓扑结构，效率达88%</li>
			 <li>高可靠性，更好的轴承电腐蚀防治措施，全密封电子驱动模块，6000V浪涌冲击；具有过温、欠压、堵转、缺相、过载等保护</li>
			 <li>宽覆盖，多个系列可覆盖20-1200W；可根据需要选择恒转矩、恒风量或恒转速功能；可自由修改指令，对应多款负载应用</li>`,
		x: 1266,
		y: 150,
		lx:-20,
		ly:-15,
	},
	{
		type:"light-btn",
		size:"S",
		src:"XFDQ-08.png",
		label:"散热器",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-08.png",
		info:`<li>可定制设计</li>
			 <li>薄型化、轻量化</li>
			 <li>低功率、低振动</li>
			 <li>可适用各种环境，防水、防尘</li>`,
		x: 315,
		y: 715,
		lx:0,
		ly:-20,
	},
	{
		type:"light-btn",
		size:"L",
		src:"XFDQ-09.png",
		label:"商用空调/热泵压缩机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-09.png",
		info:`<li>领域宽广，壳径和排量范围从小到大，实现全方位覆盖，应对多种市场领域</li>
			 <li>高效宽频，具有业内领先的能效水平、压比范围、运转范围，适用多种应用场景</li>
			 <li>可靠稳定，具有业内最高的可靠性时长标准，针对不用场景做极致化可靠设计</li>
			 <li>低噪低震，针对家用场景的低噪需求做优化设计，提升体验舒适度</li>`,
		x: 1380,
		y: 218,
		lx:37,
		ly:18,
	},
	{
		type:"light-btn",
		size:"M",
		src:"XFDQ-10.png",
		label:"洗涤排水泵",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-10.png",
		info:`<li>Halbach铁氧体磁环技术，免生锈，体积小，重量轻，结构简单，可靠性高</li>
			 <li>可集成水浸式加热管技术，水温加热保护+干烧断电保护</li>
			 <li>BMC塑封技术，防潮湿</li>`,
		x: 1323,
		y: 640,
		lx:0,
		ly:0,
	},
	{
		type:"light-btn",
		size:"M",
		src:"XFDQ-11.png",
		label:"洗衣机电机",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-11.png",
		info:`<li>无位置传感器快速起动技术，可提高洗净比，有效减少洗涤时间</li>
			 <li>正弦波矢量控制驱动，有效降低噪音和振动</li>
			 <li>智能不平衡感知技术，实现快速脱水和超低噪音</li>`,
		x: 1226,
		y: 719,
		lx:0,
		ly:0,
	},
	{
		type:"light-btn",
		size:"S",
		src:"XFDQ-12.png",
		label:"芯  片",
		url:"https://tech.midea.com/",
		html:true,
		cpImg:"assets/img/xfdq-cp/xfdq-12.png",
		info:`<li>冰箱、空调、洗衣机等大家电，以及各类小家电和电器设备的主控板和显控板</li>
			 <li>油烟机、空调内机等各类风机控制、以及破壁机、扫地机器人、电动平衡车、水泵、电动工具、飞行器等电机控制</li>`,
		x: 541,
		y: 254,
		lx:-40,
		ly:-26,
	}
]