	import {
		xfdq
	} from './xfdq-conf.js'

	import {
		gy
	} from './gy-conf.js'

	import {
		lsny
	} from './lsny-conf.js'

	import {
		zhjt
	} from './zhjt-conf.js'

	import {
		gsap
	} from "gsap";

	import {
		main
	} from './main-conf.js'

	const dist = function(p1, p2) {
		let dx = Math.abs(p2.x - p1.x);
		let dy = Math.abs(p2.y - p1.y);
		return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
	}

	const createScene = function(pixi, scene, clickHandler, data, animations, lightAnima) {
		console.log(lightAnima)
		for (let k in data) {
			let item = data[k]
			if (item.type === "anima") {
				const character = pixi.AnimatedSprite.fromFrames(animations[item.src]);
				character.animationSpeed = 1 / 3;
				character.position.set(item.x, item.y)
				character.k = k
				if (item.delay != 0) {
					setTimeout(() => {
						character.play();
					}, item.delay * 1000)
				} else {
					character.play();
				}

				if (item.turn) {
					character.scale.set(-1, 1)
				}

				scene.addChild(character);
				if (item.btnMode) {
					character.eventMode = 'dynamic';
					// 绑定点击事件以及回调方法
					character.on('pointerdown', (e) => {
						character._startPoint = {
							x: e.clientX,
							y: e.clientY
						}
					})

					character.on('pointerup', (e) => {
						let d = dist(character._startPoint, {
							x: e.clientX,
							y: e.clientY
						})
						if (d < 10) {
							clickHandler(item)
						}
					})

					character.on('pointerover', () => {
						character.cursor = "pointer";
					})

					character.on('pointerout', () => {
						character.cursor = "auto";
					})
				}
			} else if (item.type === "btn") {
				const btn = new pixi.Sprite(pixi.utils.TextureCache[item.src]);
				btn.position.set(item.x, item.y)
				btn.eventMode = 'dynamic';

				// 绑定点击事件以及回调方法
				btn.on('pointerdown', (e) => {
					btn._startPoint = {
						x: e.clientX,
						y: e.clientY
					}
				})

				btn.on('pointerup', (e) => {
					let d = dist(btn._startPoint, {
						x: e.clientX,
						y: e.clientY
					})
					if (d < 10) {
						clickHandler(item)
					}
				})

				btn.on('pointerover', () => {
					btn.cursor = "pointer";
				})

				btn.on('pointerout', () => {
					btn.cursor = "auto";
				})

				scene.addChild(btn);

			} else if (item.type === "light-btn") {
				let container = new pixi.Container();
				scene.addChild(container)
				container.position.set(item.x, item.y)
				const character = new pixi.Sprite(pixi.utils.TextureCache[item.src]);
				character.eventMode = 'dynamic';
				// 绑定点击事件以及回调方法
				character.on('pointerdown', (e) => {
					character._startPoint = {
						x: e.clientX,
						y: e.clientY
					}
				})

				character.on('pointerup', (e) => {
					let d = dist(character._startPoint, {
						x: e.clientX,
						y: e.clientY
					})
					if (d < 10) {
						clickHandler(item)
					}
				})

				character.on('pointerover', () => {
					character.cursor = "pointer";
				})

				character.on('pointerout', () => {
					character.cursor = "auto";
				})

				container.addChild(character);
				let light
				switch (item.size) {
					case "M":
						light = pixi.AnimatedSprite.fromFrames(lightAnima["lightbox-M"]);
						break;
					case "L":
						light = pixi.AnimatedSprite.fromFrames(lightAnima["lightbox-L"]);
						break;
					case "S":
						light = pixi.AnimatedSprite.fromFrames(lightAnima["lightbox-S"]);
						break;
				}
				light.position.set(item.lx, item.ly)
				light.animationSpeed = 1 / 6;
				setTimeout(() => {
					light.play();
				}, Math.random() * 3000)

				container.addChild(light);
			} else if (item.type === "mask") {
				console.log("mask", item.src)
				let textrue = pixi.Assets.get(item.src)
				const mask = new pixi.Sprite(textrue);
				mask.position.set(item.x, item.y)
				scene.addChild(mask);
			} else if (item.type === "pAnima") {
				console.log("pAnima", item.src)
				for (let i = 0; i < item.num; i++) {
					let sprite
					if (item.srcType === "png") {
						let textrue = pixi.Assets.get(item.src)
						sprite = new pixi.Sprite(textrue);
						sprite.position.set(item.x, item.y)
						scene.addChild(sprite);
					} else {
						sprite = pixi.AnimatedSprite.fromFrames(animations[item.src]);

						if (item.animationSpeed != 0) {
							sprite.animationSpeed = item.animationSpeed
						} else {
							sprite.animationSpeed = 1 / 3;
						}

						sprite.position.set(item.x, item.y)
						sprite.play();
						scene.addChild(sprite);
					}

					let duration = item.duration > 0 ? item.duration : 20

					gsap.to(sprite.position, {
						motionPath: item.motionPath,
						duration: duration,
						repeat: -1,
						delay: item.num === 1 ? item.delay : i * item.delay,
						ease: "none"
					})
				}
			}
		}
	}

	export const createMainScene = function(pixi, scene, clickHandler) {
		let bg = pixi.Sprite.from('assets/main/main-bg.png')
		scene.addChild(bg)

		const animations = pixi.Assets.cache.get('assets/main/main-0.json').data.animations;
		createScene(pixi, scene, clickHandler, main, animations, null)
	}


	export const createXFDQScene = function(pixi, scene, clickHandler) {
		let bg = pixi.Sprite.from('assets/xfdq/xfdq-bg.png')
		scene.addChild(bg)
		const lightAnima = pixi.Assets.cache.get('assets/light.json').data.animations;
		const animations = pixi.Assets.cache.get('assets/xfdq/xfdq.json').data.animations;
		createScene(pixi, scene, clickHandler, xfdq, animations, lightAnima)
	}


	export const createGYScene = function(pixi, scene, clickHandler) {
		let bg = pixi.Sprite.from('assets/gy/gy-bg.png')
		scene.addChild(bg)

		const lightAnima = pixi.Assets.cache.get('assets/light.json').data.animations;
		const animations = pixi.Assets.cache.get('assets/gy/gy-0.json').data.animations;
		createScene(pixi, scene, clickHandler, gy, animations, lightAnima)
	}


	export const createZHJTScene = function(pixi, scene, clickHandler) {
		let bg = pixi.Sprite.from('assets/zhjt/zhjt-bg.png')
		scene.addChild(bg)

		const lightAnima = pixi.Assets.cache.get('assets/light.json').data.animations;
		const animations = pixi.Assets.cache.get('assets/zhjt/zhjt.json').data.animations;
		createScene(pixi, scene, clickHandler, zhjt, animations, lightAnima)
	}



	export const createLSNYScene = function(pixi, scene, clickHandler) {
		let bg = pixi.Sprite.from('assets/lsny/lsny-bg.png')
		scene.addChild(bg)

		const lightAnima = pixi.Assets.cache.get('assets/light.json').data.animations;
		const animations = pixi.Assets.cache.get('assets/lsny/lsny.json').data.animations;
		createScene(pixi, scene, clickHandler, lsny, animations, lightAnima)
	}