export const lsny = [{
		type: "anima",
		src: "LSNY-daba",
		x: 759,
		y: 445
	},
	{
		type: "anima",
		src: "LSNY-databoard",
		x: 789,
		y: 617
	},
	{
		type: "anima",
		delay: 0.5,
		src: "LSNY-fengche",
		x: 491,
		y: 178
	},
	{
		type: "anima",
		delay: 0.2,
		src: "LSNY-fengche",
		x: 521,
		y: 279
	},
	{
		type: "anima",
		delay: 0.3,
		src: "LSNY-fengche",
		x: 634,
		y: 275
	},
	{
		type: "anima",
		delay: 0.8,
		src: "LSNY-fengche",
		x: 443,
		y: 238
	},
	{
		type: "anima",
		delay: 0.5,
		src: "LSNY-fengche",
		x: 865,
		y: 504
	},
	{
		type: "anima",
		delay: 0,
		src: "LSNY-fengche",
		x: 1029,
		y: 595
	},
	{
		type: "anima",
		delay: 0.8,
		src: "LSNY-fengche",
		x: 1115,
		y: 520
	},
	{
		type: "anima",
		delay: 0.2,
		src: "LSNY-fengche",
		x: 1097,
		y: 457
	},
	{
		type: "anima",
		delay: 0.2,
		src: "LSNY-fengche",
		x: 1544,
		y: 499
	},
	{
		type: "anima",
		delay: 1,
		src: "LSNY-fengche",
		x: 1544,
		y: 578
	},
	{
		type: "anima",
		delay: 0.5,
		src: "LSNY-fengche",
		x: 1416,
		y: 854
	},
	{
		type: "anima",
		delay: 0.6,
		src: "LSNY-fengche",
		x: 1338,
		y: 797
	},
	{
		type: "anima",
		delay: 0.2,
		src: "LSNY-fengche",
		x: 1466,
		y: 748
	},
	{
		type: "anima",
		delay: 0,
		src: "LSNY-ketouji",
		x: 217,
		y: 748
	},
	{
		type: "anima",
		delay: 0.8,
		src: "LSNY-ketouji",
		x: 361,
		y: 797
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.2,
		x: 1527,
		y: 200
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.5,
		x: 1581,
		y: 168
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.3,
		x: 1643,
		y: 133
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.84,
		x: 1694,
		y: 100
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.22,
		x: 1460,
		y: 162
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.53,
		x: 1515,
		y: 131
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.12,
		x: 1577,
		y: 94
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.8,
		x: 1630,
		y: 53
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.43,
		x: 1395,
		y: 131
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.22,
		x: 1451,
		y: 98
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.64,
		x: 1333,
		y: 91
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.2,
		x: 1385,
		y: 62
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		x: 1268,
		y: 67
	},
	{
		type: "anima",
		src: "LSNY-fengche",
		delay: 0.2,
		x: 1317,
		y: 21
	},
	{
		type: "anima",
		src: "LSNY-flag",
		x: 890,
		y: 896
	},
	{
		type: "mask",
		src: "LSNY-trunk-a.png",
		x: 482,
		y: 674
	},
	{
		type: "mask",
		src: "LSNY-trunk-b.png",
		x: 366,
		y: 674
	},
	{
		type: "anima",
		src: "LSNY-trunk",
		x: 426,
		y: 671
	},
	{
		type: "anima",
		delay: 0.9,
		src: "LSNY-fog",
		x: 394,
		y: 314
	},
	{
		type: "anima",
		delay: 0,
		src: "LSNY-fog",
		x: 351,
		y: 338
	},
	{
		type: "mask",
		src: "LSNY-bridge.png",
		x: 1518,
		y: 380
	},
	{
		type: "light-btn",
		size: "M",
		src: "LSNY-01.png",
		label: "高压变频器",
		html:true,
		cpImg: "assets/img/lsny-cp/lsny-01.png",
		info: `<li>高效的变频传动技术，助力电力、冶金、油气化工、矿业等高耗能行业节能增效</li>
			 <li>灵活，高度集成设计，体积减少10%；丰富的产品线，按需定制</li>
			 <li>高效，数字化赋能，支持在线监测、故障诊断等云服务，工作效率>97%，节能率高达30%-40%</li>
			 <li>可靠，全新控制策略，25%设备稳定性提升</li>`,
		x: 557,
		y: 393,
		lx: 0,
		ly: 0,
	},
	{
		type: "light-btn",
		size: "M",
		src: "LSNY-02.png",
		label: "工商业储能",
		html:true,
		cpImg: "assets/img/lsny-cp/lsny-02.png",
		info: `<li>采用优质高倍率充放电电芯，全新汽车级电池PACK设计</li>
			 <li>散热系统采用新的风/水冷技术，电池架布局合理，高能量密度电池系统亦可实现标准1C及以下充放电运行要求</li>
			 <li>产品符合国标及美标最新标准，极具市场竞争力</li>`,
		x: 852,
		y: 231,
		lx: 0,
		ly: 0,
	},
	// {
	// 	type: "light-btn",
	// 	size: "M",
	// 	src: "LSNY-03.png",
	// 	label: "智能微电网",
	// 	html:true,
	// 	cpImg: "assets/img/lsny-cp/lsny-03.png",
	// 	info: `<li>以分散式风电、光伏、电储能、水蓄能等产品，售电、绿证、辅助服务等交易，余热回收、能效平台、数字化运维等服务，定制化构建“低成本、低风险、低碳排” 更加灵活的柔性配电网络，实现电、热、气多维度管理</li>
	// 		 <li>能源利用率可达80%，综合能效提升15%</li>`,
	// 	x: 1044,
	// 	y: 156,
	// 	lx: 0,
	// 	ly: 0,
	// },
	{
		type: "light-btn",
		size: "M",
		src: "LSNY-04.png",
		label: "储能变流器",
		html:true,
		cpImg: "assets/img/lsny-cp/lsny-04.png",
		info: `<li>更先进的ANPC拓扑、效率更高</li>
			 <li>采用双电源冗余供电方式、系统可靠性更高</li>
			 <li>多机并联，功率均分度可达99%</li>
			 <li>具备一次调频，黑启动功能</li>
			 <li>充放电切换时间小于40ms</li>
			 <li>四象限运行，有功无功可调度</li>`,
		x: 1196,
		y: 238,
		lx: 0,
		ly: 0,
	},
	// {
	// 	type: "light-btn",
	// 	size: "M",
	// 	src: "LSNY-05.png",
	// 	label: "高压SVG",
	// 	html:true,
	// 	cpImg: "assets/img/lsny-cp/lsny-05.png",
	// 	info: `<li>集无功补偿、谐波补偿、不平衡补偿三功能于一体</li>
	// 		 <li>研发实力：长期同重点科研院所电力电子技术团队合作，高压大功率电力电子核心技术的产品已有超过数万套现场成功运行案例</li>
	// 		 <li>服务保障：系统电能质量测试、现场勘测设计、技术⽅案制订、产品制造的全周期技术支持</li>
	// 		 <li>灵活定制：以不同散热方式、产品布置策略，良好适应⻛电、光伏、冶⾦、⽯油⽯化、轨道交通、煤矿等复杂应⽤场景</li>`,
	// 	x: 1354,
	// 	y: 327,
	// 	lx: -18,
	// 	ly: -12,
	// },
	{
		type: "light-btn",
		size: "M",
		src: "LSNY-06.png",
		label: "智能电表",
		html:true,
		cpImg: "assets/img/lsny-cp/lsny-06.png",
		info: `<li>管理芯可软件升级，APP应用多样化</li>
			 <li>计量芯法制计量，可进行产品故障溯源</li>
			 <li>支持方波、尖顶波计量及单相表2-21次谐波计量，三相表2-41次谐波计量</li>
			 <li>实时监测端子温度</li>
			 <li>计量芯误差自监测，可监测计量器件基本故障（配选）</li>`,
		x: 1157,
		y: 494,
		lx: -20,
		ly: -15,
	},
	{
		type: "light-btn",
		size: "M",
		src: "LSNY-07.png",
		label: "户用储能",
		html:true,
		cpImg: "assets/img/lsny-cp/lsny-07.png",
		info: `<li>MINERGY、HiEnergy、Wisdom、ESSENTIAL四大产品线，依照入户场景需求设置单相、三相、裂相方案</li>
			 <li>其中一体机覆盖5-40kWh储能需求，分体机覆盖13.8-69kWh储能需求</li>
			 <li>依托美的集团家用体系的先进基因，集成包括光伏系统、ESS系统、热泵系统等在内的家庭能源体系，统筹光、电及热能，打造家庭综合能源管理解决方案</li>`,
		x: 1405,
		y: 685,
		lx: 15,
		ly: 3,
	},
	{
		type: "light-btn",
		size: "M",
		src: "LSNY-08.png",
		label: "工商业储能",
		html:true,
		cpImg: "assets/img/lsny-cp/lsny-08.png",
		info: `<li>采用优质高倍率充放电电芯，全新汽车级电池PACK设计</li>
			 <li>散热系统采用新的风/水冷技术，电池架布局合理，高能量密度电池系统亦可实现标准1C及以下充放电运行要求</li>
			 <li>产品符合国标及美标最新标准，极具市场竞争力</li>`,
		x: 1103,
		y: 862,
		lx: 30,
		ly: 10,
	},
	{
		type: "light-btn",
		size: "L",
		src: "LSNY-09.png",
		label: "EMS能源管理系统",
		html:true,
		cpImg: "assets/img/lsny-cp/lsny-09.png",
		info: `<li>用能可视，通过数据采集及数据可视化，直观体现整体用能情况</li>
			 <li>综合分析，强大数据支撑，把握每一分节能机会</li>
			 <li>优化增效，数据过大波动时自适应报警及条件，提升运维效率</li>
			 <li>需求预测，助力企业进行成本核算</li>`,
		x: 814,
		y: 545,
		lx: 20,
		ly: 10,
	}
]