<template>
	<div id="app">
		<Pixi></Pixi>
	</div>
</template>

<script>
	import Pixi from "./components/pixi.vue";

	export default {
		components: {
			Pixi
		},
		name: 'App',
	}
</script>

<style>
	html,
	body,
	#app {
		margin: 0;
		padding: 0;
		overflow: overlay;
		font-family: 'Avenir';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		background: $background-color;
	}
</style>