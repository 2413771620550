<template>
	<div class="modal in out" :style="{display:display , opacity:opacity}" @touchmove.prevent.self>
		<div v-if="opened" class="closebg" @click.stop="()=>$emit('closed')"></div>
		<div class="modal-content" :style="{bottom:bottom + 'px' , opacity:opacity }">
			<div class="modal-header">
				<span class="close" @click="()=>$emit('closed')">&times;</span>
				<h2 style="text-align:center; font-family: MideaType-Bold;">{{header}}</h2>
			</div>
			<slot></slot>
			<div class="modal-footer">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		gsap
	} from "gsap";
	export default {
		name: 'pdialog',
		data() {
			return {
				display: "none",
				opened: false,
				opacity: 0,
				bottom: -300
			}
		},
		props: {
			header: {
				type: String,
				default: "窗口"
			},
			width: {
				type: Number,
				default: 1000
			},
			height: {
				type: Number,
				default: 500
			}
		},
		computed: {},
		mounted() {

		},
		methods: {
			stopTouch(e) {
				e.preventDefault()
			},
			open() {
				this.display = "block"
				this.$emit('opend')
				// setTimeout(()=>{
				// 	this.opened = true
				// },200)
				gsap.to(this, {
					bottom: 0,
					opacity: 1,
					duration: 0.2,
					onComplete: () => {
						this.opened = true
					}
				});
			},
			close() {
				gsap.to(this, {
					bottom: -300,
					opacity: 0,
					duration: 0.1,
					ease: "sine.in",
					onComplete: () => {
						console.log("closeddddd")
						this.display = "none"
						this.opened = false
					}
				});
			}
		},
	}
</script>

<style scoped>
	@import 'fonts.css';


	.noscroll {
		overflow: hidden;
		position: fixed;
	}

	/* 弹窗 */
	.modal {
		display: none;
		/* 默认隐藏 */
		position: fixed;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0, 0, 0, 0.4);
		/* 	-webkit-animation-name: fadeIn;
		-webkit-animation-duration: 0.4s;
		animation-name: fadeIn;
		animation-duration: 0.4s */
	}

	.closebg {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0, 0, 0, 0.0);
	}

	/* 弹窗内容 */
	.modal-content {
		position: fixed;
		bottom: 0;
		background-color: #fefefe;
		border-radius: 20px 20px 0px 0px;
		width: 100%;
		/* 		-webkit-animation-name: slideIn;
		-webkit-animation-duration: 0.2s;
		animation-name: slideIn;
		animation-duration: 0.2s; */
		/* box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px; */
	}

	/* 关闭按钮 */
	.close {
		color: #999999;
		float: right;
		font-size: 36px;
		font-weight: bold;
	}

	.close:hover,
	.close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}

	.modal-header {
		height: 70px;
		left: 50%;
		padding: 2px 16px;
		color: #666666;
	}

	.modal-footer {
		height: 10px;
		padding: 2px 4px;
		background-color: #FFFFFF;
		color: white;
	}

	/* 添加动画 */
	@-webkit-keyframes slideIn {
		from {
			bottom: -300px;
			opacity: 0
		}

		to {
			bottom: 0;
			opacity: 1
		}
	}

	@keyframes slideIn {
		from {
			bottom: -300px;
			opacity: 0
		}

		to {
			bottom: 0;
			opacity: 1
		}
	}

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0
		}

		to {
			opacity: 1
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0
		}

		to {
			opacity: 1
		}
	}
</style>