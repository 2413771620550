export const main = [
	{
		type: "anima",
		src: "MAIN-LSNY-trunkA",
		x: 456,
		y: 133
	}, 
	// {//工业 传送带动画开始
	// 	type: "anima",
	// 	src: "MAIN-GY-lvdai-A",
	// 	x: 498,
	// 	y: 100
	// },
	// {
	// 	type: "anima",
	// 	src: "MAIN-GY-lvdai-B",
	// 	x: 1186,
	// 	y: 253
	// },
	{
		type: "anima",
		src: "MAIN-GY-dingbu",
		x: 1221,
		y: 151
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "MAIN-GY-washmachine.png",
		x: 1105,
		y: 226,
		num: 10,
		delay: 2,
		duration: 20, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1105,
				y: 226
			}, {
				x: 1218,
				y: 157
			}, {
				x: 1237,
				y: 148
			}, {
				x: 1247,
				y: 145
			}, {
				x: 1260,
				y: 153
			}, {
				x: 1438,
				y: 265
			}]
		}
	},
	{
		type: "anima",
		src: "MAIN-GY-arm",
		x: 1344,
		y: 183,
	},
	{
		type: "anima",
		delay: 0.5,
		src: "MAIN-GY-arm",
		x: 1395,
		y: 211,
	},
	{
		type: "anima",
		src: "MAIN-GY-arm",
		turn: true,
		x: 1140,
		y: 190,
	},
	{
		type: "anima",
		src: "MAIN-GY-armB",
		x: 1223,
		y: 343
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "anima", //png单帧，anima多帧动画
		src: "MAIN-GY-carryrobot",
		x: 1250,
		y: 190,
		num: 1,
		delay: 0,
		duration: 9, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1250,
				y: 190
			}, {
				x: 1363,
				y: 249
			}, {
				x: 1322,
				y: 273
			}, {
				x: 1207,
				y: 216
			},
			{
				x: 1250,
				y: 190
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "mask",
		src: "MAIN-GY-HMI.png",
		x: 1038,
		y: 215
	},
	{
		type: "anima",
		src: "MAIN-GY-HMI",
		x: 1115,
		y: 241
	},
	{
		type: "anima",
		src: "MAIN-GY-chanche",
		x: 1142,
		y: 306
	},
	{
		type: "mask",
		src: "MAIN-GY-lvdai.png",
		x: 1159,
		y: 155
	},
	{
		type: "anima",
		src: "MAIN-GY-jiqishijue",
		x: 1146,
		y: 174
	},
	{
		type: "anima",
		src: "MAIN-GY-kezi",
		x: 1342,
		y: 251
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 1096,
		y: 105
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 855,
		y: 210
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 779,
		y: 168
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 610,
		y: 352
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 525,
		y: 293
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 301,
		y: 112
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 202,
		y: 101
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fengche",
		x: 171,
		y: 184
	},
	{
		type: "anima",
		src: "XFDQ-aircondition",
		x: 1220,
		y: 432
	},
	{
		type: "anima",
		src: "XFDQ-BBQ",
		x: 1062,
		y: 658
	},
	{
		type: "anima",
		src: "MAIN-XFDQ-01computer",
		x: 1342,
		y: 640
	},
	{
		type: "anima",
		src: "MAIN-XFDQ-washmachine",
		x: 1441,
		y: 631
	},
	{
		type: "anima",
		src: "MAIN-ZHJT-bike",
		x: 831,
		y: 762
	},
	{
		type: "anima",
		src: "MAIN-ZHJT-carlight",
		x: 785,
		y: 645
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "MAIN-ZHJT-train.png",
		x: -165,
		y: 295,
		num: 1,
		delay: 0,
		duration: 9, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: -195,
				y: 303
			}, {
				x: 1055,
				y: 1033
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "mask",
		src: "bridgeB.png",
		x: 174,
		y: 449
	},
	{
		type: "mask",
		src: "MAIN-road.png",
		x: 927,
		y: 991
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "MAIN-ZHJT-airplane.png",
		x: 1911,
		y: 301,
		num: 1,
		delay: 0,
		duration: 9, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1911,
				y: 301
			}, {
				x: 1220,
				y: -107
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fog",
		x: 499,
		y: 120
	},
	{
		type: "anima",
		src: "MAIN-LSNY-fog",
		delay: 0.7,
		x: 540,
		y: 140
	},
	{
		type: "mask",
		src: "MAIN-ZHJT-ship.png",
		x: 265,
		y: 841
	},
	{
		type: "mask",
		src: "bridgeA.png",
		x: 291,
		y: 417
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "MAIN-ZHJT-car03.png",
		x: 1913,
		y: 508,
		num: 2,
		delay: 2,
		duration: 10, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1913,
				y: 508
			}, {
				x: 936,
				y: 1077
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "MAIN-ZHJT-car05.png",
		x: 1890,
		y: 519,
		num: 2,
		delay: 5,
		duration: 20, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1880,
				y: 523
			}, {
				x: 940,
				y: 1080
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "MAIN-ZHJT-car04.png",
		x: 1911,
		y: 573,
		num: 1,
		delay: 0,
		duration: 10, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1911,
				y: 573
			}, {
				x: 1054,
				y: 1075
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "pAnima", //pAnima程序动画
		srcType: "png", //png单帧，anima多帧动画
		src: "MAIN-ZHJT-car06.png",
		x: 1898,
		y: 606,
		num: 3,
		delay: 3,
		duration: 8, //秒
		animationSpeed: 1 / 3,
		motionPath: {
			path: [{
				x: 1898,
				y: 606
			}, {
				x: 1086,
				y: 1075
			}],
			curviness: 0,
			alignOrigin: [0.5, 0.5],
			autoRotate: false
		}
	},
	{
		type: "anima",
		name: "xfdq",
		btnMode: true,
		delay: 0,
		src: "MAIN-BT-d",
		x: 1125,
		y: 558
	},
	{
		type: "anima",
		name: "gy",
		btnMode: true,
		delay: 3,
		src: "MAIN-BT-b",
		x: 1138,
		y: 207
	},
	{
		type: "anima",
		name: "lsny",
		btnMode: true,
		delay: 2.3,
		src: "MAIN-BT-a",
		x: 538,
		y: 247
	},
	{
		type: "anima",
		name: "zhjt",
		btnMode: true,
		delay: 4,
		src: "MAIN-BT-c",
		x: 548,
		y: 577
	},
	{
		type: "mask",
		src: "MAIN-BT-biaoti.png",
		x: 843,
		y: 344
	}
]